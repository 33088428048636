import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AlertController, NavController } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.page.html',
  styleUrls: ['./mapa.page.scss'],
})
export class MapaPage implements OnInit {

  map: Leaflet.Map;
  lat: number = 21.9934775;
  lng: number = -99.0176878;
  user:string; 
  time_interval:any;  
  objetos:any;
  markers:any = [];
  markersLineas:any = [];
  lineaTempObjeto:any = [];
  lineasObjetos:any;
  lineasPushAgrupadas:any =[];
  numTempObjeto:number = 0;

  constructor( private _api: ApiService, private router: Router, public alertController:AlertController, 
              public navCtrl:NavController ) { 
    this.user  = localStorage.getItem("user");  
  }

  ngOnInit() {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log("Inicia objetos");
      }

      if (event instanceof NavigationEnd) {
          console.log("Termina objetos");
          if( this.time_interval ){     
            clearInterval( this.time_interval );
          }
          // Hide loading indicator
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }
    });

    //aqui lleva loading y carga de objetos
    setTimeout(()=>{
      this.leafletMap();
      this.getObjetos(1);
    }, 500);
  }

  ionViewWillEnter(){  
    if( this.time_interval ){     
      clearInterval( this.time_interval );
    }
    // this.getObjetos();
    this.time_interval = setInterval(()=>{
      this.getObjetos(0);
    }, 10000);

    setTimeout(()=>{
      this.map.invalidateSize();
    }, 500);
  }

  leafletMap() {

    let osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		let osmAttrib = '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors';
		let osm  = Leaflet.tileLayer(osmUrl, { maxZoom: 19, attribution: osmAttrib });
    this.map = Leaflet.map('mapId', { center: [this.lat, this.lng], zoom: 5 });

    const here = {
      id: '6AJleReU2wy5FIYdcHUZ',
      code: '2sYGPV-IeanNImtVlcmNpw'
    }
    const style = 'normal.day';

    Leaflet.control.layers({
      'OSM': osm.addTo(this.map),
      "Humanitarian": Leaflet.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { attribution: 'Map &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> | Tiles &copy; <a href=\"http://hot.openstreetmap.org\">Humanitarian OSM Team</a>', minZoom: 0, maxZoom: 20 }),
      'HERE': Leaflet.tileLayer(`https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}&ppi=320`,{
        attribution:"HERE",
        maxZoom:19
      }),
        'HERE HYBRID': Leaflet.tileLayer(`https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}`,{
        attribution: 'HERE hybrid',
        maxZoom: 19
      }),
      'Google Streets': Leaflet.tileLayer('http://www.google.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}', {
	      attribution: 'google',
	      maxZoom: 20,
      }),           
      "Google Traffic": Leaflet.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20,
          minZoom: 2,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      "Google Hybrid": Leaflet.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20
      })  
    }).addTo(this.map);

  }

  getObjetos( status:number ){
    console.log("status", status);
    let data_send = { user: this.user };
    this._api.getObjetos(data_send).subscribe((data:any)=>{
      console.log(data);
      this.objetos = data.data;

      this.deleteMarkersLines();
      this.deleteMarkers();  
      this.numTempObjeto++;  

      if( this.objetos.length == 0 ){
        if( data.status == 0 ){
          // this.notificacion_msj( "Objetos", data.mensaje, 'danger' );
          this.presentAlert(data.mensaje);
        }else if(data.status == 2 ){
          this.presentAlert("Usuario inactivo comunicate con nosotros");
          localStorage.clear();
          this.navCtrl.navigateRoot('login');
        }
      }else{
        let num = 0;        
        
        let markersGroup = [];
        
        for( let dataUbic of data.data ){
          num++;
          this.addMrcas(dataUbic, 1);
          markersGroup.push( new Array( dataUbic.lat, dataUbic.lng ) );
          if( num == data.data.length ){       
            if( status == 1 ){
              this.map.fitBounds( markersGroup, {padding: [50,50]} );            
            }   
          }
        }

        this.estelaRecorrido( data.data );
        
      }

    });
  }

  estelaRecorrido( data:any){

    if( this.numTempObjeto == 1 ){

      for( let objeto of data ){
        this.lineaTempObjeto.push( { "imei":objeto.imei, data:[[objeto.lat, objeto.lng]] } );        
      }

    }else if( this.numTempObjeto > 1 && this.numTempObjeto < 12 ){

      let num = 0;
      for( let objeto of this.lineaTempObjeto ){        
        for( let objetoNew of data ){
          if( this.lineaTempObjeto[num].imei == objetoNew.imei ){
            this.lineaTempObjeto[num].data.push( [ objetoNew.lat, objetoNew.lng ] );                                 
          }                   
        }

        if( num == data.length - 1  ){
          if( this.lineaTempObjeto.length > 0 ){
            this.creaLineasRecorrido( this.lineaTempObjeto );
          }        
        }
        num++;
      }
          
    }else{
      this.numTempObjeto   = 0;
      this.lineaTempObjeto = [];
      if(this.lineasObjetos){
        this.map.removeLayer(this.lineasObjetos);
      }
      this.lineasPushAgrupadas = [];
    }    

  }

  creaLineasRecorrido( data:any ){    
    console.log("creaLineasRecorrido", data);
    let num = 0;
    for( let unidad of data ){
      num++;   
      this.lineasPushAgrupadas.push( unidad.data );      
      if( data.length == num ){        
        if( this.lineasObjetos ){
          this.map.removeLayer(this.lineasObjetos);
        }
        this.lineasObjetos = new Leaflet.polyline(this.lineasPushAgrupadas, {color: '#2b3643', weight:4}).addTo(this.map);        
      }
    }
  }

  addMrcas( dataUbic:any, status:number ){
    let iconGps:any;
    let marker:any;
    // console.log( dataUbic );    
    
    if( dataUbic.speed <= 4 &&  dataUbic.acc == 1  ){

      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-amarilla.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }else if(  dataUbic.speed <= 4 &&  dataUbic.acc == 0  ){

      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-roja.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }else if(  dataUbic.speed > 4 &&  dataUbic.acc == 1 ){
      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-verde.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }

    if( iconGps ){
      console.log("recibe encendido");
      marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })
                        .bindPopup(`<strong>Nombre:</strong> ${dataUbic.nombre}<br><strong>Dirección:</strong>${dataUbic.direccion}
                                    <br><strong>Cerca de:</strong>${dataUbic.pdi}<br><strong>Ubicación:</strong>
                                    <a href="https://www.google.com.mx/maps?q=${this.number(dataUbic.lat)},${this.number(dataUbic.lng)}"  target="_blank">
                                    ${dataUbic.lat},${dataUbic.lng}</a><br><strong>Velocidad: </strong>${this.speed( dataUbic.speed )}Km/h<br>
                                    <strong>Status: </strong>${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}<br>
                                    <strong>Motor:</strong>${this.motor(dataUbic.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}<br>`).addTo(this.map);
    }else{
      /*Por si no recibe encendido hacerlo con velicudad*/
      if( dataUbic.speed > 4 ){
        console.log("NO encendido");
        iconGps = Leaflet.icon({
            iconUrl: this.validaUrl( dataUbic.url_icon, '../../../assets/marca-verde.png'),
            iconSize: this.iconSize( dataUbic ),
            iconAnchor: this.iconAnchor( dataUbic ),
            popupAnchor: [-1, -30]
        });
      
        marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })
                          .bindPopup(`<strong>Nombre:</strong> ${dataUbic.nombre}<br><strong>Dirección:</strong>${dataUbic.direccion}
                                      <br><strong>Cerca de:</strong>${dataUbic.pdi}<br><strong>Ubicación:</strong>
                                      <a href="https://www.google.com.mx/maps?q=${this.number(dataUbic.lat)},${this.number(dataUbic.lng)}"  target="_blank">
                                      ${dataUbic.lat},${dataUbic.lng}</a><br><strong>Velocidad:</strong>${this.speed(dataUbic.speed)}Km/h<br>
                                      <strong>Status: </strong>${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}<br>
                                      <strong>Motor:</strong>${this.motor(dataUbic.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}`).addTo(this.map);

      }else{
        
        iconGps = Leaflet.icon({
            iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/marca-roja.png'),
            iconSize: this.iconSize( dataUbic ),
            iconAnchor: this.iconAnchor( dataUbic ),
            popupAnchor: [-1, -30]
        });

        marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })
                          .bindPopup(`<strong>Nombre:</strong> ${dataUbic.nombre}<br><strong>Dirección:</strong>${dataUbic.direccion}
                                      <br><strong>Cerca de:</strong>${dataUbic.pdi}<br><strong>Ubicación:</strong>
                                      <a href="https://www.google.com.mx/maps?q=${this.number(dataUbic.lat)},${this.number(dataUbic.lng)}"  target="_blank">
                                      ${dataUbic.lat},${dataUbic.lng}</a><br><strong>Velocidad:</strong>${this.speed(dataUbic.speed)}Km/h<br>
                                      <strong>Status: </strong>${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}<br>
                                      <strong>Motor:</strong>${this.motor(dataUbic.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}`).addTo(this.map);
      }
    }

    if( status == 1 ){
      
      marker.bindTooltip(dataUbic.nombre, {permanent:true, offset: Leaflet.point({x: this.poitOffset( dataUbic ), y: 0}), direction:'left' });
      this.markers.push(marker);
    }

    if( status == 2 ){
      // this.markersHistorial.push(marker);
    }

  }

  poitOffset( data:any ){

    if( data.url_icon == undefined ){
      return -10;
    }

    if( data.url_icon !== "null" ){
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) );        
        return size.offset;
      }else{
        return -25;
      }
    }else{
      return -10;
    }
  }

  motor( status:any ){

    if( status == "0" ){
      return "Apagado";
    }else if( status == "1" ){
      return "Encendido";
    }else{
      return "!";
    }
  }

  getTIMESTAMP(timestamp:any) {
    let months:any = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    let date:any = new Date(timestamp);
    let year:any = date.getFullYear();
    let month:any = ("0"+(date.getMonth()+1)).substr(-2);
    let day = ("0"+date.getDate()).substr(-2);
    let hour = ("0"+date.getHours()).substr(-2);
    let minutes = ("0"+date.getMinutes()).substr(-2);
    let seconds = ("0"+date.getSeconds()).substr(-2);

    return months[month-1]+" "+day+", "+year+" "+hour+":"+minutes+":"+seconds;
  }

  tipoStatus( speed:number, acc:number ){

    if( acc == 1 && speed < 5 ){
      return "detenido encendido";
    }

    if( speed > 5 ){
      return "en movimiento";
    }else{
      if( acc == 0 ){
        return "apagado"
      }
      return "detenido";
    }

  }

  timeConversion(millisec:any) {	
    if( millisec ){				
        let seconds = (millisec / 1000).toFixed(1);
        var minutes = (millisec / (1000 * 60)).toFixed(1);
        var hours = (millisec / (1000 * 60 * 60)).toFixed(1);
        var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

        if (parseFloat(seconds) < 60) {
            return seconds + " seg";
        } else if (parseFloat(minutes) < 60) {
            return minutes + " min";
        } else if (parseFloat(hours) < 24) {
            return hours + " hrs";
        } else {
            return days + "Días"; 
        }
    }else{
        return 0;
    }
}

  speed( speed:any ){
    if( speed ){   
      let newSpeed = parseFloat( speed ) / 0.62137;  
      return newSpeed.toFixed(1);
    }else{
      return "0";
    }
  }

  private number(value: any): number {
    if( value == "0" ){
      return 0;
    }else{
      return +value;
    }
  }

  validaUrl( url_server:any, url:any ){
    
    if( url_server !== "null" && url_server !== undefined ){
      return `${url_server}`;
    }else{
      return `${url}`;
    }
  }

  validaAngulo( status:any, angulo:any ){    
    //console.log( status, angulo );
    if( status == undefined || status == 2 ){      
      return angulo;
    }

    if( status == 1 ){
      return angulo;
    }else{
      return 0;
    } 
  }

  iconSize( data:any ){

    if( data.url_icon == undefined || data.url_icon == "null" ){
      return [28, 40];
    }
    //console.log(data.url_icon);
    if( data.url_icon !== "null" ){         
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) );        
        return [ size.size_ancho, size.size_largo ]
      }else{      
        return [ 25, 50 ]
      }
    }else{           
      return [28, 40];
    }
  }

  iconAnchor( data:any ){

    if( data.url_icon == undefined || data.url_icon == "null" ){
      return [14, 20];
    }

    if( data.url_icon !== "null" ){
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) ); 
        return [ size.size_ancho/2, size.size_largo/2 ]
      }else{
        return [ 12.5, 25 ]
      }
    }else{
      return [14, 20];
    }
  }

  async presentAlert( message:string ) {
    const alert = await this.alertController.create({
      // header: 'Alert',
      subHeader: 'Mensaje',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  deleteMarkers(){
    for(var i = 0; i < this.markers.length; i++){
			this.map.removeLayer(this.markers[i]);
		}
  }

  deleteMarkersLines(){
    for(var i = 0; i < this.markersLineas.length; i++){
			this.map.removeLayer(this.markersLineas[i]);
		}
  }

}
